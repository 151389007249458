.designer_profile {
  color: #000;
  .widget-designer-design {
    background-color: #000;
    .content-designer-design {
      margin-top: 5%;
      margin-bottom: 7%;
    }
    .title-content {
      color: #fff;
    }
    .list-designer-design {
      height: 9em;
      .list-image {
        height: 100%;
      }
      .item-image {
        background-color: #fff;
        height: 100%;
        margin-right: 4%;
      }
    }
  }
  .widget-style-category {
    margin-top: 3%;
    margin-bottom: 5%;
    position: relative;
    .content-arrow-left {
      height: 47%;
    }
    .content-arrow-right {
      height: 47%;
    }
    .caroufredsel_wrapper {
      height: 100%;
    }
    .gallery-content-image {
      height: 30em;
      margin-top: 5%;
      padding: 0;
      .list-content-image {
        height: 100%;
        padding: 0;
        .item-content {
          height: 100%;
          padding: 0;
          margin-left: 1.5em;
          margin-right: 1.5em;
          width: 15%;
          &:last-child {
            margin-right: 0;
          }
        }
      }
      .item-image-content {
        height: 47%;
        box-shadow: 1px 1px 4px #999;
        background-size: cover;
        background-position: center center;
        position: relative;
        cursor: pointer;
        margin-bottom: 20%;
      }
    }
  }
  .star-image {
    width: 20%;
    margin: 5% auto;
  }
  .container-slider-browse-design {
    padding: 0;
    margin-top: 3%;
  }
  .transparant {
    border-top: 8px solid #000;
  }
  .container-categories-browse-design {
    background: #eeeeee;
  }
}
