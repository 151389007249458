.widget-how-it-work {
  background-color: #000;
  @media (min-width: 20px) and (max-width: 767px) {
    padding-top: 2em;
    padding-bottom: 2em;
  }
  .title-content {
    color: #fff;
    h3 {
      color: #fff !important;
    }
  }
  .content-how-it-work {
    margin-bottom: 6%;
    .list-how-it-work {
      .item-how-it-work {
        color: #fff;
        .number-image {
          text-align: center;
          h2 {
            font-family: 'brandon-grotesque', sans-serif;
            font-size: 1.3em;
            margin: 0;
            font-weight: 700;
          }
        }
        .image-how-it-work {
          width: 22%;
          text-align: center;
          margin: 0 auto;
        }
        .title-how-it-work {
          color: #fff;
          font-size: 0.8em;
          h4 {
            color: #fff !important;
            font-weight: 700;
          }
        }
        .separator-line {
          border-top-color: #fff;
        }
        .desription-how-it-work {
          letter-spacing: 1px;
          margin-top: 9%;
          p {
            text-align: center;
            font-family: 'Sanchez', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 1em;
          }
          @media (min-width: 20px) and (max-width: 767px) {
            margin-top: 0;
          }
        }
      }
    }
  }
  .currency-symbol {
    font-size: 2em;
    text-transform: initial;
  }
  .currency-price {
    font-size: 5em;
    line-height: 0.75 !important;
  }
}
