.button-container-center {
  &.black {
    background-color: #000;
  }
  &.white {
    background-color: #fff;
  }
  .button-center {
    .list-button {
      position: relative;
      .black {
        color: #fff;
        background-color: #000;
        border: 1px solid #fff;
        -webkit-transition: background-color 0.3s;
        transition: background-color 0.3s;
        &:hover {
          background-color: #fff;
          color: #000;
          border: 1px solid #000;
          a {
            color: #000;
          }
        }
      }
      .white {
        color: #000;
        background-color: #fff;
        border: 1px solid #000;
      }
      .green {
        color: #fff;
        border: 1px solid #fff;
        background-color: #85d6af;
        -webkit-transition: background-color 0.3s;
        transition: background-color 0.3s;
        &:hover {
          background-color: #fff;
          color: #000;
          border: 1px solid #000;
          a {
            color: #000;
          }
        }
      }
    }
  }
  .description-button {
    width: 18em;
    margin: 1.5em auto 0;
    p {
      text-align: center;
      font-family: 'Sanchez';
      font-size: 0.9em;
    }
    a {
      color: #fff;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .wait-class {
    cursor: default !important;
  }
  a {
    cursor: default;
    &:focus {
      color: #000 !important;
    }
  }
}
