.widget-studio {
  position: relative;
  .content-studio {
    position: relative;
    margin-bottom: 6%;
    height: 100%;
    .title-studio {
      position: relative;
    }
  }
  .slider-studio {
    position: relative;
    padding: 0 5%;
    .content-slider {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
      .content-list {
        position: relative;
        height: 100%;
      }
    }
    .slider-image {
      height: 100% !important;
      padding-left: 0;
      padding-right: 0;
      .image-item {
        padding: 0;
        height: 100%;
        background-size: cover;
        background-position: center center;
        cursor: pointer;
        a {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
        }
      }
    }
    .content-arrow-left,
    .content-arrow-right {
      height: 100%;
      img {
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: auto;
        width: 60%;
        margin: auto;
      }
    }
  }
  .our-designer {
    position: relative;
    height: 100%;
    .slider-studio {
      position: relative;
      height: 9em;
    }
    .image-item {
      cursor: default;
      padding: 0;
      position: relative;
      display: block;
      &.black {
        .transparant {
          background-color: #000 !important;
        }
        span {
          color: #fff !important;
          text-transform: uppercase;
          font-size: 0.7em;
          font-weight: 700;
          word-break: break-word;
        }
      }
      &.white {
        .transparant {
          background-color: #fff !important;
        }
        span {
          color: #000 !important;
          text-transform: uppercase;
          font-size: 0.7em;
          font-weight: 700;
          word-break: break-word;
        }
      }
      .transparant {
        opacity: 0;
      }
    }
  }
  .quote-hover {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    color: #fff;
    text-align: center;
    font-family: 'brandon-grotesque', sans-serif;
    font-style: italic;
    font-weight: 300;
    left: 0;
    right: 0;
    margin: auto;
    height: 100%;
    padding-left: 10%;
    padding-right: 10%;
    .text-center {
      display: table;
      height: 100%;
      width: 100%;
      .center {
        display: table-cell;
        vertical-align: middle;
      }
    }
    span {
      text-transform: capitalize;
    }
    .image {
      height: 100%;
      background-size: cover;
      background-position: center center;
    }
  }
  .image {
    height: 100%;
    background-size: cover;
    background-position: center center;
  }

  @media (max-width: 768px) {
    .our-designer .content-designer .image-item {
      left: 0;
      margin: 0;
    }
    .slider-studio {
      padding: 0 8%;
    }
  }
  @media (min-width: 800px) and (max-width: 850px) {
    min-height: 400px;
    .our-designer .content-designer .image-item {
      left: 0;
      margin: 0;
    }
    .slider-studio {
      padding: 8%;
    }
  }
  @media (max-width: 767px) {
    .our-designer .content-designer .image-item {
      left: 0;
      margin: 0;
    }
    .slider-studio {
      padding: 0;
      .content-arrow-left {
        padding: 0;
        img {
          right: auto;
        }
      }
      .content-arrow-right {
        padding: 0;
        img {
          left: auto;
        }
      }
    }
  }
}
