.widget-envelope-callout {
  padding: 4em 0;
  background-repeat: no-repeat;
  background-size: cover;
  html.mobile & {
    background-position-x: 100%;
  }
  html.tablet.portrait & {
    background-position-x: 60%;
  }
  @media (max-width: 1399px) {
    html.desktop & {
      background-position: 40% center;
    }
  }
  @media (min-width: 1400px) {
    html.desktop & {
      padding: 5.5em 0;
    }
  }
  @media (min-width: 1650px) {
    html.desktop & {
      padding: 8em 0;
    }
  }
  @media (min-width: 1900px) {
    html.desktop & {
      background-size: 100% 100%;
    }
  }
  @media (min-width: 2000px) {
    html.desktop & {
      background-position: center center;
    }
  }

  .bg-image {
    position: relative;
    .image {
      width: 100%;
    }
  }
  .outer-box {
    margin: 1em auto;
    .main-title {
      line-height: 1.2em;
      text-transform: uppercase;
      font-family: 'brandon-grotesque', sans-serif;
      font-style: normal;
      text-align: center;
      font-size: 1em;
      font-weight: 700;
      margin: 0;
    }
    .subtitle {
      font-style: italic;
      font-family: 'adobe-caslon-pro', sans-serif;
      font-size: 1.5em;
      text-align: center;
      margin-top: 1em;
      html.mobile & {
        margin-top: 2em;
        font-size: 1.5em;
      }
      @media (min-width: 1650px) {
        html.desktop & {
          margin-top: 2em;
        }
      }
    }
    .content-wrapper {
      margin-top: 6em;
      html.mobile & {
        margin-top: 6em;
      }
      @media (min-width: 1400px) {
        html.desktop & {
          margin-top: 6em;
        }
      }
      .content-single {
        html.mobile & {
          margin-top: 4em;
        }
        .image {
          margin: auto;
          width: 20%;
        }
        .title {
          font-size: 0.8em;
          min-height: 40px;
          h4 {
            font-weight: 700;
            text-align: center;
            margin-top: 2em;
            margin-bottom: 1em;
          }
        }
        .separator {
          width: 25%;
          margin: auto;
          margin-bottom: 2.5em;
          hr {
            border-top: 1px solid #000000;
          }
        }
        .desc {
          width: 75%;
          margin: auto;
          html.mobile & {
            @media (max-width: 350px) {
              width: 75%;
            }
            @media (max-width: 450px) and (min-width: 351px) {
              width: 60%;
            }
          }
          html.tablet & {
            width: 85%;
          }
          @media (min-width: 1650px) {
            html.desktop & {
              width: 70%;
              margin-top: 3.5em;
            }
          }

          p {
            text-align: center;
            margin-bottom: 1.5em;
            font-family: 'Sanchez', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 1em;
          }
          .button-part {
            margin-top: 4em;
            text-align: center;
            html.mobile & {
              margin-top: 4em;
            }
            a {
              width: 100%;
              font-size: 0.85em;
              &.btn {
                padding: 1.05em 1em;
              }
            }
          }
        }
      }
    }
  }
}

.widget-as-featured-in {
  position: relative;
  .list-featured-slide {
    position: relative;
    text-align: center;
    .title {
      position: relative;
      margin: 5% auto;
    }

    .container,
    .row,
    .slider,
    .content-wrapper,
    .image {
      height: 100% !important;
    }

    .logo-slider {
      .slider {
        margin-bottom: 5%;
        html.mobile & {
          margin-bottom: 10%;
        }
        .content-arrow {
          height: 100%;
        }
        .content-wrapper {
          text-align: center;
          .slick-list {
            .slick-track {
              margin: auto;
              .featured-item {
                padding: 0 15px;

                &:focus,
                &:visited,
                &:hover {
                  border: none;
                  outline: none;
                }
                html.mobile & {
                  padding: 0 8px;
                }

                a {
                  text-decoration: none;
                  border: none;
                  outline: none;
                  &:focus,
                  &:visited,
                  &:hover {
                    text-decoration: none;
                    border: none;
                    outline: none;
                  }

                  .image {
                    &:focus,
                    &:visited,
                    &:hover {
                      border: none;
                      outline: none;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
