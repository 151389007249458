%baseArrow {
  position: absolute;
  height: 100%;
  top: 0;
  bottom: 0;
  width: 50px;
  z-index: 2;
}
.new-design-home {
  position: relative;

  .content-widget-video-home {
    position: relative;
    padding-top: 35%;
    height: 100%;
    display: table;
    width: 100%;
    @media (min-width: 20px) and (max-width: 767px) {
      padding-top: 100%;
    }
    .title-content {
      color: #000000;
      margin: 3% auto 2%;
      @media (min-width: 20px) and (max-width: 800px) {
        font-size: 0.7em;
        padding: 15px;
      }
      .mobile.portrait.smallscreen & {
        .title {
          font-size: 1.5em;
          h3 {
            font-size: 1.5em;
          }
        }
      }
    }

    &#edm_campaign {
      .title-content {
        margin: 3% auto 4%;
        background-color: #ffffff;
        padding: 25px;
      }
    }

    .content-video {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }

    .video-item {
      position: relative;
      height: 100%;
      width: 100%;
      display: table;
      overflow: hidden;

      &.white-text {
        .sameH3,
        .subtitle {
          color: #fff !important;
          text-shadow: 1px 1px 5px #000 !important;
        }
      }
    }

    .item-source {
      position: relative;
      width: 100%;
      height: 100%;
      @media (min-width: 20px) and (max-width: 767px) {
        .image > img {
          width: 150vw !important;
          height: 100vw;
        }
      }
    }

    video {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      z-index: 1;
      opacity: 0.95;

      html.firefox & {
        transform: translate(-50%, -30%);
      }
    }

    .content-title-video {
      z-index: 2;
      position: absolute;
      height: 100%;
      width: 100%;

      .item-title-video {
        width: 100%;
        vertical-align: unset;
        text-align: unset;
        position: unset;
        top: unset;
        left: unset;
        transform: unset;
        display: flex;
        max-width: 1140px;
        height: 100%;
        margin: 0% auto;
        align-items: flex-end;
        flex-direction: column;
        justify-content: center;
        @media (max-width: 960px) {
          width: 100%;
        }
      }

      .title {
        h1 {
          letter-spacing: 5px;
          font-size: 3em;
          @media screen and (max-width: 1024px) {
            font-size: 2em;
          }
          @media (min-width: 20px) and (max-width: 767px) {
            font-size: 1.3em;
            letter-spacing: 2px !important;
          }
        }
      }

      .subtitle {
        font-size: 1.5em;
        @media (min-width: 20px) and (max-width: 767px) {
          font-size: 2em;
          width: 72vw;
          br {
            display: none;
          }
        }
      }
    }
  }
  .content-widget-coloumn {
    padding: 1.5em 0;
    color: #000;

    .list-item {
      .item-content-title {
        text-align: center;

        .item-title {
          width: 100%;
          margin: auto;
        }

        h2 {
          font-weight: 700;
          font-family: 'brandon-grotesque', sans-serif;
          font-size: 1.5em;
          letter-spacing: 3px;
          text-transform: uppercase;
          html.mobile & {
            max-height: 50px !important;
          }
        }

        p {
          text-align: center;
          font-weight: 700;
          font-size: 1em;
        }
      }
    }
  }
  .content-widget-big-slider {
    position: relative;

    .widget-bg-header {
      .content-bg-transparant {
        position: absolute;
        width: 100%;
        max-width: 1070px;
        height: 100%;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }

      .content-bg-head {
        .head-bg-list {
          .item {
            .bg-item-slider {
              position: absolute;
              width: 100%;
              height: 100%;
            }

            .text-button-container {
              text-align: center;
              position: absolute;
              width: 100%;
              height: 30%;
              min-height: 360px;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              z-index: 1;
              margin: auto;
              max-width: 956px;
              html.mobile & {
                display: table;
                height: auto;
                min-height: auto;
                bottom: 80px;
                top: auto;
                width: 100%;
              }
              html.firefox & {
                bottom: 0;
                height: 100%;

                .content-textbutton-wrap {
                  position: absolute;
                  bottom: 80px;
                }
              }

              .text-item {
                margin: 0 auto;
                position: relative;
                width: 100%;

                .title {
                  font-family: 'brandon-grotesque', sans-serif;
                  font-size: 3em;
                  text-transform: uppercase;
                  font-weight: 700;
                  color: #000;
                  letter-spacing: 5px;
                  margin: auto;
                  text-shadow: 0px 0px 10px #ccc;
                  .mobile.portrait.smallscreen & {
                    font-size: 1.5em;
                    h3 {
                      font-size: 1.5em;
                    }
                  }
                }

                .subtitle {
                  font-size: 1.5em;
                  .mobile.portrait.smallscreen & {
                    font-size: 1em;
                  }
                }

                @media screen and (max-width: 768px) {
                  font-size: 1.75em;
                }
                @media screen and (max-width: 1000px) {
                  font-size: 2.25em;
                }
                .mobile.portrait.smallscreen & {
                  padding: 0;
                }
              }

              .button-shop-container {
                margin-top: 2em;
                width: 100%;

                button {
                  color: #fff;
                  background-color: #000;
                }
              }

              .content-textbutton-wrap {
                width: 100%;
                margin: 0 auto;
                padding: 6em 0;

                .back-layer-transparant {
                  position: absolute;
                  left: 0;
                  right: 0;
                  top: 0;
                  bottom: 0;
                  opacity: 0.5;
                  z-index: -1;
                  background-color: #fff;
                  width: 90%;
                  max-width: 956px;
                  margin: auto;
                  html.mobile & {
                    width: 51em;
                    max-width: auto;
                  }
                }
              }
            }
          }
        }
      }

      .content-arrow-left {
        left: 0;
        @extend %baseArrow;
        @media screen and (max-width: 1280px) {
          left: 10%;
        }
        @media screen and (max-width: 1152px) {
          left: 5%;
        }
        @media screen and (max-width: 1024px) {
          left: 0;
        }
        @media screen and (max-width: 700px) {
          display: none;
        }

        img {
          width: 100%;
        }
      }

      .content-arrow-right {
        right: 0;
        @extend %baseArrow;
        @media screen and (max-width: 1280px) {
          right: 10%;
        }
        @media screen and (max-width: 1152px) {
          right: 5%;
        }
        @media screen and (max-width: 1024px) {
          right: 0;
        }
        @media screen and (max-width: 700px) {
          display: none;
        }

        img {
          width: 100%;
        }
      }
    }

    .carousel-indicators {
      a {
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin: 10px;
        font-size: 1.1em;
        text-decoration: none !important;

        &::before {
          content: '\f111';
        }

        &.selected {
          color: #fff;
        }
      }

      span {
        display: none;
      }

      .active {
        background-color: #fff;
        border: 1px solid #fff;
      }

      li {
        border: 1px solid #000;
        background-color: #000;
      }
    }

    .content-title-slider {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      display: table;
      width: 100%;
      text-align: center;
    }
  }
  .widget-sample-pack {
    .title-content {
      color: #fff;
      padding-top: 0;
    }

    .content-sample-pack {
      position: relative;
      height: 100%;
      padding-bottom: 6%;
      padding-top: 2%;

      .bg-transparant {
        opacity: 1;

        &.dark-bg {
          background-color: #000000;
          opacity: 0.5;
        }
      }
    }

    .zero-conten {
      position: relative;
    }

    .center-content-wrap {
      display: table;
      vertical-align: middle;
    }

    .title-button-wrap {
      width: 100%;
      display: table-cell;
      vertical-align: middle;
      padding-top: 10%;

      .title-content {
        .subtitle {
          font-size: 1.5em;
        }
      }
    }
  }
  .button-inverse {
    &.btn-lg {
      padding: 1.05em 4em;
      font-size: 0.85em;
      font-family: 'brandon-grotesque', sans-serif;
      font-style: normal;
      border: 1px solid #000;
    }

    &:hover {
      background-color: #fff !important;
      color: #000 !important;
    }
  }
  .content-grid-banner {
    margin: 1em auto;
    margin-bottom: 3em;

    .transparant {
      opacity: 0.3;
      z-index: 1;
    }

    .text-image {
      position: absolute;
      bottom: -20%;
      z-index: 2;
      margin: 1em 0;
      font-family: 'brandon-grotesque', sans-serif;
      font-size: 1.1em;
      font-weight: 600;
      text-transform: capitalize;
      width: 100%;
      text-align: center;
    }

    .use-white-text {
      .text-image {
        color: #ffffff;
      }
    }

    .item-image {
      position: relative;
      padding-top: 20em;
    }

    .detail-item {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 30px;
      @media (max-width: 960px) {
        overflow: hidden;
      }
    }



    .image {
      width: 100%;
      position: absolute;
      height: 100%;
      img {
        position: absolute;
        height: 100%;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        html.mobile.portrait & {
          height: auto;
        }
        html.tablet & {
          width: auto;
        }
      }
    }

    .button-part {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      right: 0;
      margin: auto;
      display: table;
      opacity: 0;
      -webkit-transition-property: opacity;
      /* Safari */
      -webkit-transition-duration: 0.5s;
      /* Safari */
      transition-property: opacity;
      transition-duration: 0.5s;
      html.mobile &,
      html.tablet & {
        opacity: 1;
      }
      html.ie & {
        width: 100%;
      }

      .btn-upper {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        text-transform: uppercase;

        .btn-lg {
          padding: 1.05em 3em;
          white-space: normal;
        }
      }

      &:hover {
        opacity: 1;
      }
    }
  }
  .widget-studio {
    .our-designer {
      .button-item {
        width: auto;
      }
    }
  }
}
div.item-title-video>div {
  padding: 0px !important;
}
div.item-title-video {
  padding: 0px 15px !important;
}
@import '../widgets/home-env-print.scss';
@import '../widgets/how-it-work.scss';
@import '../widgets/sample-pack.scss';
@import '../widgets/studio.scss';
@import '../widgets/designer-profile.scss';
@import '../widgets/instagram.scss';
@import '../libs/button-center.scss';
