.widget-sample-pack {
  position: relative;
  height: 34em;
  @media (min-width: 20px) and (max-width: 991px) {
    height: auto !important;
  }
  @media (max-width: 800px) {
    html.tablet & {
      height: auto !important;
    }
  }
  .center-content-wrap {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    @media (min-width: 20px) and (max-width: 991px) {
      position: relative;
      padding: 3em 0;
    }
  }
  .content-sample-pack {
    position: relative;
    height: 100%;
    padding-bottom: 6%;
    padding-top: 2%;
    .bg-transparant {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 0;
      height: 100%;
      background-size: cover;
      background-position: center center;
      opacity: 0.75;
    }
    .sample-pack-title {
      color: #000;
      z-index: 2;
      position: relative;
      h3 {
        margin: 0;
      }
    }
  }
  .title-button-wrap {
    padding-top: 0 !important;
  }
  .title-content {
    padding-top: 3% !important;
    margin-bottom: 0;
    z-index: 2;
    position: relative;
    @media (min-width: 20px) and (max-width: 767px) {
      margin-top: 0;
    }
    @media (max-width: 800px) {
      margin-top: 0;
    }
  }
  .testimoni-content {
    padding-top: 3%;
    margin-top: 8em;
    margin-bottom: 0;
    z-index: 2;
    position: relative;
    .title {
      margin-bottom: 0.5em;
    }
    .testimoni {
      font-family: 'brandon-grotesque', sans-serif;
      font-style: normal;
      font-weight: 400;
      text-align: center;
      font-size: 1.2em;
    }
    .name-testimoni {
      font-family: 'brandon-grotesque', sans-serif;
      font-style: normal;
      font-weight: 400;
      text-align: center;
      font-size: 1.4em;
      text-transform: uppercase;
    }
    .content-arrow-left {
      position: absolute;
      height: 50%;
      top: 100%;
      left: 10%;
      width: 50px;
      @media screen and (max-width: 1280px) {
        left: 10%;
      }
      @media screen and (max-width: 1199px) {
        top: 60%;
        left: 10%;
      }
      @media screen and (max-width: 1152px) {
        top: 60%;
        left: 10%;
      }
      @media screen and (max-width: 1024px) {
        top: 60%;
        left: 10%;
      }
      @media screen and (max-width: 767px) {
        top: 50%;
        left: 10%;
      }
      @media screen and (max-width: 700px) {
        display: none;
        img {
          width: 50%;
        }
      }

      img {
        top: auto;
        bottom: 227px;
        width: 100%;
      }
    }
    .content-arrow-right {
      position: absolute;
      height: 50%;
      top: 100%;
      right: 10%;
      width: 50px;
      @media screen and (max-width: 1280px) {
        right: 10%;
      }
      @media screen and (max-width: 1199px) {
        top: 60%;
        right: 10%;
      }
      @media screen and (max-width: 1152px) {
        top: 60%;
        right: 10%;
      }
      @media screen and (max-width: 1024px) {
        top: 60%;
        right: 10%;
      }
      @media screen and (max-width: 767px) {
        top: 50%;
        right: 10%;
      }
      @media screen and (max-width: 700px) {
        display: none;
        img {
          width: 50%;
        }
      }

      img {
        top: auto;
        bottom: 227px;
        width: 100%;
      }
    }

    @media (min-width: 20px) and (max-width: 767px) {
      margin-top: 0;
    }
    @media (max-width: 800px) {
      html.tablet & {
        margin-top: 0;
      }
    }
  }

  .zero-conten {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    height: 100%;
    width: 100%;
    display: table;
    @media (min-width: 20px) and (max-width: 767px) {
      position: relative;
    }
    @media (max-width: 800px) {
      html.table & {
        position: relative;
      }
    }

    .button-container-center {
      display: table-cell;
      vertical-align: middle;
    }
    .white.button-item {
      border: 0 !important;
    }
  }

  .button-center {
    margin-bottom: 0;
  }
}
