@mixin sliderNextPrev($pos) {
  position: absolute;
  width: 3em;
  height: 2em;
  top: 0;
  bottom: 0;
  #{$pos}: 0;
  margin: auto;
  font-size: 1em;
  z-index: 998;
  padding: 0.5em 0.5em;
  color: #fff;
  font-family: monospace;
  text-decoration: none;
  html.mobile & {
    padding: 0;
  }
}
#widget-instagram {
  &.widget-instagram {
    position: relative;
    margin: 6% auto;
    margin-top: 0;
    .title-content {
      margin-bottom: 2em;
    }
  }
  .instagram-slider-wrapper {
    position: relative;
    padding: 0 3em;
    .slider-nav {
      padding: 0;
      height: 0;
      .btn-prev {
        @include sliderNextPrev(left);
      }
      .btn-next {
        @include sliderNextPrev(right);
      }
    }
    .instagram-slider {
      &:after {
        content: '';
        display: block;
        clear: both;
      }
      .slider-item {
        width: 25%;
        float: left;
        .tile-grid-item {
          width: 100%;
          display: block;
          .item-wrapper {
            height: 0px;
            padding-top: 100%;
            position: relative;
            overflow: hidden;
            background-color: #888;
            .image {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center center;
              -webkit-transition: all 0.75s ease-in-out;
              transition: all 0.75s ease-in-out;
            }
            .hover {
              font-family: 'brandon-grotesque', sans-serif;
              opacity: 0;
              position: absolute;
              top: 0;
              left: 0;
              background-color: #000;
              background-color: rgba(0, 0, 0, 0.8);
              width: 100%;
              height: 100%;
              text-align: center;
              display: block;
              text-decoration: none;
              background-repeat: no-repeat;
              background-position: right 1em top 1em;
              background-size: 0.7em;
              -webkit-transition: opacity 0.75s;
              transition: opacity 0.75s;
              -webkit-transition: opacity 0.75s, background-position 0.75s,
                background-size 0.75s;
              transition: opacity 0.75s, background-position 0.75s,
                background-size 0.75s;

              &:before {
                content: '';
                display: inline-block;
                height: 100%;
                vertical-align: middle;
                margin-right: -0.3em;
              }
              .hover-content {
                display: inline-block;
                vertical-align: middle;
                text-align: center;
                color: #fff;
                padding: 1em;
                -webkit-transform: scale(0.5, 0.5);
                -ms-transform: scale(0.5, 0.5);
                transform: scale(0.5, 0.5);
                -webkit-transition: all 0.75s ease;
                transition: all 0.75s ease;
                .time {
                  padding-top: 0.5em;
                }
              }
              .likes {
                position: absolute;
                bottom: 0.5em;
                left: 0.5em;
                color: #fff;
                .likes-icon {
                  display: inline-block;
                  background-repeat: no-repeat;
                  background-position: -104px -357px;
                  height: 24px;
                  width: 24px;
                  vertical-align: middle;
                }
                .likes-count {
                  vertical-align: middle;
                  padding-left: 5px;
                }
              }
              .image {
                -webkit-transform: scale(1.2, 1.2);
                -ms-transform: scale(1.2, 1.2);
                transform: scale(1.2, 1.2);
              }
            }

            &:hover {
              .image {
                -webkit-transform: scale(1.2, 1.2);
                -ms-transform: scale(1.2, 1.2);
                transform: scale(1.2, 1.2);
              }
              .hover {
                opacity: 1;
                background-size: 1.2em;
                .hover-content {
                  -webkit-transform: scale(1, 1);
                  -ms-transform: scale(1, 1);
                  transform: scale(1, 1);
                }
              }
            }
          }
        }
      }
    }
  }
}
.new-design-home {
  #widget-instagram {
    .title-content {
      margin-top: -2em;
    }
  }
}
